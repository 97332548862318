<template>
  <div>
    <div class="p-4">
      <ecs-data-list>
        <ecs-data-list-item identifier="Cite" type="mono" :loading="processingRequest">
          {{ mark.citation }}
        </ecs-data-list-item>
        <ecs-data-list-item v-if="isVideoDepo || hasLineData" :progress="clipPercent" identifier="Video" :loading="processingRequest">
          <ecs-flex-row v-if="hasVidClip" :gap="4">
            <div v-ec-tooltip="{ content: (!isVideoDepo ? 'Load synced video to view clips' : '')}" >
              <ecs-button @click="vidClipClick" :disabled="!isVideoDepo" v-ec-tooltip="{ content: 'Play video clip'}" :icon="thisClipPlaying ? 'stop' : 'play'" icon-only size="sml" />
            </div>
            {{ clipStartTime }} - {{ clipEndTime }} ({{ clipRuntime }})
          </ecs-flex-row>
          <ecs-flex-row v-else :gap="4">
            <ecs-button disabled type="secondary" icon="play" icon-only size="sml" />
            <span class="subtle">No synced video</span>
          </ecs-flex-row>
        </ecs-data-list-item>
        <ecs-data-list-item v-if="showDeponent" :identifier="deponentLabel" :loading="processingRequest">{{ deponents }}</ecs-data-list-item>
      </ecs-data-list>
    </div>

    <ecs-collapse title="Excerpt Text" id="excerpt-text" :visible="collapsibles[0].open" indent="md" border-bottom border-top>
      <template v-slot:controls>
        <ecs-button @click.stop="$emit('copyWithCite')" type="secondary" icon="clipboard" icon-only aria-label="Copy w/ Cite" title="Copy w/ Cite" />
      </template>
      <ecs-skeleton-loader v-if="processingRequest" type="multi" :count="3" />
      <pre v-else-if="markText" class="m-0 citation">{{ markText }}</pre>
    </ecs-collapse>

    <ecs-section indent="md" border-bottom title="Coding">
      <ec-tag-view v-bind="{ tags, headline: 'Annotation Tags', loading: processingRequest }" :key="count" />

      <h4 class="subline mt-4 mb-2">Favorability</h4>
      <ecs-rating-favorability :rating="favorability" label :loading="processingRequest" />
    </ecs-section>

    <ecs-section v-if="profiles.length" indent="md" border-bottom title="People & Entities">
      <ec-profiles-viewer v-bind="{ profiles, matterId }" />
    </ecs-section>

    <ecs-collapse v-if="hasChronEntry" :title="chronEntryHeading" :class="{ subtle: hasInactiveChronEntry }" id="transcript-annotation-chron" indent="md" border-bottom>
      <ecs-data-list id="transcript-annotation-chron" indent="md" border-bottom>
        <ecs-data-list-item identifier="Entry Date" :loading="processingRequest">
          <ec-date-time v-bind="{ value: mark.annotation.date, utc: true, withTime: true }" format="informal" />
        </ecs-data-list-item>
        <ecs-data-list-item identifier="Entry Type" :loading="processingRequest">{{ mark.annotation.doc_type }}</ecs-data-list-item>
        <ecs-data-list-item identifier="Entry Headline" full :loading="processingRequest">{{ mark.annotation.headline }}</ecs-data-list-item>
        <ecs-data-list-item v-if="mark.annotation.summary" identifier="Summary" full :loading="processingRequest">
          <ecs-formatted small><div v-html="mark.annotation.summary" /></ecs-formatted>
        </ecs-data-list-item>
      </ecs-data-list>
    </ecs-collapse>

    <ecs-collapse v-else-if="mark.annotation.summary" title="Summary" id="transcript-annotation-summary" indent="md" border-bottom>
      <ecs-formatted small :loading="processingRequest"><div v-html="mark.annotation.summary" /></ecs-formatted>
    </ecs-collapse>

    <ecs-collapse title="User Details" id="annotation-details" :visible="collapsibles[1].open" indent="md" border-bottom>
      <ecs-data-list>
        <ecs-data-list-item identifier="Created on" :loading="processingRequest">
          <ec-date-time v-bind="{ value: mark.creationDateTime, utc: false, withTime: true }" format="informal" />
        </ecs-data-list-item>
        <ecs-data-list-item identifier="Created by" :loading="processingRequest">{{ author }}</ecs-data-list-item>
      </ecs-data-list>
    </ecs-collapse>

    <!--NOTE THESE ARE HIDDEN FOR NOW-->
    <span class="hidden">
      <h4>Exhibits</h4>
      <ec-exhibits-viewer v-bind="{ exhibits }" />
    </span>

    <span class="hidden">
      <h4>Entries</h4>
      <ec-entries-viewer v-bind="{ entries }" />
    </span>
  </div>
</template>

<script>
import EcProfilesViewer from './profiles/profiles_viewer'
import EcExhibitsViewer from './exhibits/exhibits_viewer'
import EcEntriesViewer from './entries/entries_viewer'
import EcTagsViewer from './tags/tags_viewer'
import EcDateTime from 'ec-common/components/utility/datetime'
import TranscriptAnnotationMixin from 'ec-common/components/viewer/mixins/transcript_annotation_mixin'
import EcAnnotationVidClip from 'ec-common/components/viewer/transcript/components/mixins/vid-clip'
import EcTagView           from 'ec-common/components/utility/tag-view.vue'

export default {
  components: { EcTagsViewer, EcProfilesViewer, EcExhibitsViewer, EcEntriesViewer, EcDateTime, EcTagView },
  mixins: [TranscriptAnnotationMixin, EcAnnotationVidClip],
  emits: ['copyWithCite'],

  props: {
    dateFormat    : Object,
    entryTags     : Array,
    documentId    : Number,
    hasChronEntry : Boolean,
    chronEntryHeading : String,
    hasInactiveChronEntry : Boolean
  },

  data(){
    return {
      excerptVisible: true,
      tags: [],
      count: 1 // UI no longer reflecting changes to the tags array, force it via key
    }
  },

  computed: {
    annotation() {
      return this.mark.annotation || {}
    },

    favorability() {
      return this.annotation.favorability || 'Unrated'
    },

    profiles() {
      return this.annotation.profiles || []
    },

    entries() {
      return this.annotation.entries || []
    },

    exhibits() {
      return this.annotation.exhibits || []
    },

    citation(){
      return this.mark.citation
    },

    allTags(){
      return this.$store.getters['tags/all']
    },

    membershipMatterIds () {
      return this.$store.getters['memberships/matterIds']
    },

    matterUsers () {
      if (_.includes(this.membershipMatterIds, _.toString(this.matterId)))
        return this.$store.getters['memberships/findByMatter'](this.matterId)
      return []
    },

    author(){
      const author = _.find(this.matterUsers, ['user_id', this.mark.authorId])
      return author ? author.user_name : null
    },

    processingRequest(){
      return this.$store.getters['annotations/processing']
    }
  },

  methods: {
    getTagId(tagName){
      return _.find(this.allTags, ['name', tagName])
    }
  },

  watch:{
    entryTags: {
      deep: true, immediate: true, handler() {
        this.tags = []
        _.each(this.entryTags, t => {
          const tag = this.getTagId(_.isString(t) ? t : t.name)
          if (_.isNil(tag)) {
            const name = _.isObject(t.name) ? t.name.name : t.name
            this.tags.push({ name, id: Math.floor(Math.random() * (9000 - 8000) + 8000)}) // random ID is ok since this section uses the name
          }
          else {
            this.tags.push(tag)
          }
        })
        this.count = this.count + 1
      }
    }
  },

  mounted(){
    if (!this.matterUsers.length)
      this.$store.dispatch('memberships/index', {matter_id: this.matterId, commit: true})

    if (this.autoplay) // give it a few seconds to buffer
      setTimeout(() => { this.vidClipClick() }, 2000)
  }
}
</script>

<style scoped>
  .subtle {
    opacity: .7
  }
</style>
