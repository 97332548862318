.runtime-footer[data-v-48c197f6] {
  padding: 3px 0;
  font-size: 12px;
}
.runtime-footer > div[data-v-48c197f6] {
    position: relative;
    z-index: 1;
}
.runtime-footer .runtime[data-v-48c197f6],
  .runtime-footer .current[data-v-48c197f6] {
    color: rgba(101, 104, 122, 0.7);
}
.runtime-footer .total[data-v-48c197f6] {
    color: #65687A;
    font-weight: 500;
}
.runtime-footer .video-progress[data-v-48c197f6] {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #E9F3FF;
    z-index: 0;
}
.ecs-tab-bar-segment .ecs-tab-button[data-v-48c197f6] {
  line-height: 22px;
  padding: 0px 8px;
}
