.templates-index-header[data-v-5ec6f010] {
  padding: 0 var(--spacing-20) var(--spacing-10) var(--spacing-20);
}
.modal-tab-bar[data-v-5ec6f010] {
  padding: 0 var(--spacing-25);
  border-bottom: none !important;
}
.modal-tab-bar .ecs-tab-button[data-v-5ec6f010]:not(:last-child) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.modal-tab-bar .ecs-tab-button[data-v-5ec6f010]:last-child {
    flex-shrink: 0;
}
