.tag-tree > *[data-v-21a6a790] {
  width: 100%;
}
.tag-tree-search[data-v-21a6a790] {
  margin-bottom: var(--spacing-10);
}
.empty-text[data-v-21a6a790] {
  padding: var(--spacing-5) 6px;
}
.loading .item[data-v-21a6a790] {
  display: flex;
  align-items: center;
  height: 32px;
  gap: var(--spacing-10);
  padding: 0 var(--spacing-10);
  width: 100%;
}
.loading.loading-contained[data-v-21a6a790] {
  border-radius: 6px;
  border: 1px solid var(--color-gray-3);
  padding: var(--spacing-5);
}
