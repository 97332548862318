.select-container[data-v-63930af3] {
  background-color: var(--color-white);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-gray-4);
  height: 404px;
}
.select-scroll-container[data-v-63930af3] {
  height: 362px;
  border-radius: var(--border-radius-large);
}
.select-header[data-v-63930af3] {
  border-bottom: 1px solid var(--color-gray-3);
  height: 40px;
  padding: 0 var(--spacing-5);
}
.select-item[data-v-63930af3] {
  padding: var(--spacing-5) 0;
  min-height: 32px;
}
.select-item.has-deponent[data-v-63930af3] {
    min-height: 44px;
}
.load-more-button[data-v-63930af3] {
  margin-top: var(--spacing-5);
  width: 100%;
}
.exhibit-items[data-v-63930af3] {
  padding: var(--spacing-5);
}
.exhibit-item[data-v-63930af3] {
  padding: var(--spacing-10) var(--spacing-15);
  background-color: var(--color-white);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--elevation-05);
  width: 100%;
  min-height: 88px;
}
.exhibit-item-headline[data-v-63930af3] {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
}
.exhibit-item-icon[data-v-63930af3], .exhibit-item-type[data-v-63930af3] {
    flex-shrink: 0;
}
.input-block[data-v-63930af3] {
  position: relative;
  width: 100px;
}
