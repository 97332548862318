.bulk-select[data-v-75ec24d0] {
  margin: 6px 0px;
}
.remove-button[data-v-75ec24d0] {
  opacity: 0;
  transition: .2s ease-in-out;
}
.remove-button.visible[data-v-75ec24d0] {
    opacity: 1;
}
.automation-conflict:hover .remove-button[data-v-75ec24d0] {
  opacity: 1;
}
