.manage-tags-toolbar[data-v-1cf04903] {
  width: 100%;
  padding: 0 var(--spacing-10) 0 var(--spacing-15);
}
.bulk-select-tags[data-v-1cf04903] {
  flex-shrink: 0;
  font-weight: var(--font-weight-medium);
}
.bulk-select-tags span[data-v-1cf04903] {
    font-weight: var(--font-weight-normal);
    color: var(--color-gray-10);
    margin-left: var(--spacing-5);
}
.search-bar-enter-active[data-v-1cf04903],
.search-bar-leave-active[data-v-1cf04903] {
  transition: all 0.3s ease-out;
}
.search-bar-enter-from[data-v-1cf04903],
.search-bar-leave-to[data-v-1cf04903] {
  opacity: 0;
}
.tag-list[data-v-1cf04903] {
  padding: var(--spacing-10) var(--spacing-5);
  height: 100%;
  overflow: auto;
}
.form-container[data-v-1cf04903] {
  background: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-10);
  margin: 0 -10px 12px;
}
