.favorability-count[data-v-0b9d842a] {
  font-size: var(--type-scale-3-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--type-scale-3-line-height);
  font-variant-numeric: tabular-nums;
  text-align: right;
}
.type-label[data-v-0b9d842a] {
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  color: var(--color-gray-12);
}
