@charset "UTF-8";
[data-v-24880a56] .ecs-data-grid-head-cell-text {
  white-space: normal;
  text-align: center;
  line-height: 1em;
}
.indent-spacer[data-v-24880a56] {
  height: 1px;
  flex-shrink: 0;
  width: 28px;
}
.indent-spacer.sml[data-v-24880a56] {
    width: 16px;
}
.form-indent[data-v-24880a56] {
  padding-left: var(--spacing-40);
}
.roles-diff[data-v-24880a56] {
  margin: 0 -8px 0 -8px;
  text-align: left;
  background-color: var(--color-white);
  box-shadow: 0 0 0 1px rgba(71, 75, 96, 0.05), 0 1px 4px 0 rgba(71, 75, 96, 0.1);
  padding: var(--spacing-10) var(--spacing-20);
  border-radius: var(--border-radius-medium);
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
}
.roles-diff .permission[data-v-24880a56] {
    margin-bottom: var(--spacing-5);
}
.roles-diff .change[data-v-24880a56] {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--spacing-5);
}
.roles-diff .removed[data-v-24880a56] {
    color: var(--color-gray-8);
}
.roles-diff .removed[data-v-24880a56]:after {
      content: '→';
      margin-left: var(--spacing-5);
}
.roles-diff .added[data-v-24880a56] {
    color: var(--color-blue-10);
    font-weight: var(--font-weight-medium);
}
