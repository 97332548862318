.designation-type[data-v-1eed4b5a] {
  border-radius: 4px;
  padding: 0 2px 0 4px;
  font-size: 12px;
}
.designation-type .ecs-sticker[data-v-1eed4b5a] {
    position: absolute;
    bottom: -2px;
    right: -2px;
}
.play-clip-button[data-v-1eed4b5a], .play-clip-button[data-v-1eed4b5a]:disabled {
  opacity: 0;
}
.mark-card:hover .play-clip-button[data-v-1eed4b5a] {
  opacity: 1;
}
.objection-bases[data-v-1eed4b5a] {
  cursor: help;
}
