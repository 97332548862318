.mf-collapse .mf-party[data-v-5ed0c573] {
  display: none;
}
.mf-entry[data-v-5ed0c573] {
  position: relative;
}
.mf-entry-link.folder[data-v-5ed0c573] {
  z-index: 5;
}
.navigate-box[data-v-5ed0c573] {
  margin-left: -32px;
  background: var(--color-gray-2);
  border: 1px solid var(--color-gray-4);
  border-radius: 4px;
  padding: 0 8px;
  height: 30px;
}
.navigate-box .icon[data-v-5ed0c573] {
    transition: .2s ease-in-out;
    color: var(--color-gray-10);
}
.navigate-box[data-v-5ed0c573]:hover {
    background: var(--color-gray-3);
    border-color: var(--color-gray-5);
}
.navigate-box:hover .icon[data-v-5ed0c573] {
      transform: translateX(4px);
}
.mf-entry-drop-zone[data-v-5ed0c573] {
  position: absolute;
  inset: 0 5px 0 0;
  z-index: 3;
  transition: .12s;
  cursor: copy;
  background-blend-mode: multiply;
  border-radius: 4px;
}
.mf-entry-drop-zone[data-v-5ed0c573]:hover {
    background: rgba(25, 204, 116, 0.1);
}
.mf-entry-non-drop-zone[data-v-5ed0c573] {
  position: absolute;
  inset: 0 5px 0 0;
  z-index: 3;
  transition: .12s;
  border-radius: 4px;
  cursor: not-allowed;
}
