.ecs-sidebar-header-title[data-v-11a69fe4] {
  min-width: 0;
}
.entry-pane[data-v-11a69fe4] {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
}
.entry-pane.pane-preview[data-v-11a69fe4] {
    background-color: var(--color-gray-1);
}
.entry-pane.overlay-sidebar .ecs-sidebar-header[data-v-11a69fe4],
  .entry-pane.overlay-sidebar .ecs-sidebar-content[data-v-11a69fe4],
  .entry-pane.overlay-sidebar .ecs-sidebar-footer[data-v-11a69fe4] {
    padding-right: 54px;
}
.pane-selector[data-v-11a69fe4] {
  margin-left: -8px;
}
.pane-selector-title[data-v-11a69fe4] {
    min-width: 0;
}
.pane-selector-title .ecs-text[data-v-11a69fe4] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
}
